import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { Icon } from 'antd'
import store from '../store/index'
import './home.scss'
import bg from './../images/indexbg.jpg'
import title4 from './../images/i4.png'
import pic99 from './../images/i99.png'
import m2 from './../images/home-m2.png'
import Header from './header'
import Footer from './footer'
import NewsList from './news.json'
@observer
class Home extends Component {
  // function Home (props) {
  // console.log(props)
  componentDidMount() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }
  render() {
    return (
      <div className="home">
        <div
          className="fixMenus"
          style={
            store.scroll < 120
              ? { transform: 'translateY(-85px)' }
              : { transform: 'translateY(0)' }
          }
        >
          <Header fixed={true} location={'home'}></Header>
        </div>
        <div className="bg">
          <img src={bg} alt="" className="bgimg" />
          <div className="absMenus">
            <Header location={'home'}></Header>
          </div>
          <div className="bgblock">
            <div className="words">
              <h1>主动型风险管理</h1>
              <h2>Proactive Risk Management</h2>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="indexContent">
            <p>
              源堡科技是一家专注于网络安全量化风险管理的科技企业。公司基于自主研发的网络安全风险自动化评估与管理平台，为企业提供风险量化评估分析、风险持续性监测和风险预警管理等服务。
            </p>

            <p>
              源堡科技作为网络安全量化风险管理领域的开创者和引领者，创新性的将网络安全风险量化技术与保险保障范围相结合，为保险和再保险公司提供网络安全保险、数据安全保险等相关产品的模型开发和量化风控系统，帮助保险机构解决核保、定价等核心业务难点，并为投保企业提供“保险+风险管理+服务”的主动型风险管理解决方案。
            </p>

            <p>
              源堡科技由院士领衔，汇聚了网络安全和金融领域专家。公司总部位于北京，目前已在南京、上海、深圳落地三家子公司。
            </p>

            <p>
              服务领域覆盖政府、金融、能源、电信、医疗、汽车、教育、互联网等多个重点行业。客户包括人保、太保、平安、国寿、中再、瑞再、国家电网、中信集团、中核凯利、汽车之家等国内外知名企业。
            </p>

            <p>
              自成立以来，公司已完成三轮融资，投资方包括中信创投、高瓴创投、中交鼎信、中关村发展前沿基金、启迪之星等知名机构，累计融资数亿元。
            </p>

            <h3>网络安全保险+网络安全服务=主动型风险管理</h3>
            <div className="img">
              <img src={pic99} alt="" />
            </div>
          </div>
        </div>

        <div className="risk">
          <h3>主动型风险管理方案</h3>
          <p>
            主动型风险管理：通过“网络安全保险+网络风险管理”相结合的方式，为投保客户提供全方位安全保障，从源头降低出险率，帮助客户减少由于网络安全事故带来的损失。最终目的是：“不出险！少出险！出小险！”
          </p>
        </div>

        <div className="content product2">
          <div className="colms1">
            <div className="iconbox">
              <Icon type="global" />
            </div>
            <div className="text">
              <h5>网络安全保险</h5>
              <p>
                利用保险的金融属性，分散风险；其最核心和关键的部分就是网络安全事件事前的风险防范机制和事后的损失补偿机制。
              </p>
            </div>
          </div>
          <div className="colms1">
            <div className="iconbox2">
              <Icon type="global" />
            </div>
            <div className="text">
              <h5>网络风险管理</h5>
              <p>
                通过风险评估与量化发现风险，处置风险；利用网络安全保险转移风险；利用技术平台持续监控风险。
              </p>
            </div>
          </div>
        </div>

        <div className="content mt50">
          <div className="indexContent">
            <h3>主动型风险管理价值：站在用户风险防控的角度进行安全保障</h3>
            <div className="jiazhi">
              <div className="contcolm">
                <div className="cbox">
                  <div className="iconbox3">
                    <Icon type="database" />
                  </div>
                  <div className="text">
                    <h5>厂商：利益驱动—销售思维</h5>
                    <p>1、怎么才能卖出更多产品或服务呢？</p>
                    <p>
                      2、用户风险怎么处置呢？上全套的产品或系统？SOC?SIEM？还是SOAR?{' '}
                    </p>
                    <p>
                      3、用户发生安全事故了？没关系，应急响应就好，再购买一些安全设备增强防护能力？或者再购买大的APT监测平台？威胁发现平台？应急响应平台？
                    </p>
                  </div>
                </div>

                <div className="cbox">
                  <div className="iconbox3">
                    <Icon type="team" />
                  </div>
                  <div className="text">
                    <h5>用户：业务驱动-保障思维</h5>
                    <p>1、我的核心业务和资产是什么？怎么保护他们不出事？</p>
                    <p>
                      2、这些业务或资产有什么安全风险？营业中断了怎么办？哪些业务会受影响？哪些数据可能泄露？哪些终端可能中勒索病毒？
                    </p>
                    <p>
                      3、在“有限的时间、有限的人力、有限的预算”情况下，风险如何处置？
                    </p>
                    <p>4、如何尽量保证“不出事，少出事，出小事”？</p>
                  </div>
                </div>

                <div className="cbox">
                  <div className="iconbox3">
                    <Icon type="star" />
                  </div>
                  <div className="text">
                    <h5>源堡：保险驱动-保障思维</h5>
                    <p>1、用户的核心业务和资产有哪些？他们潜在损失多大？</p>
                    <p>
                      2、这些业务或资产有什么安全风险？营业中断的概率和损失是多少？数据泄露的概率和损失是多少？终端感染勒索病毒的概率和损失是多少？
                    </p>
                    <p>3、哪些高风险必须处置，防止出险？</p>
                    <p>4、如何尽量保证“不出险，少出险，出小险”？</p>
                  </div>
                </div>
              </div>
              <div className="imgcolm">
                <h4>目标导向</h4>
                <img src={m2} alt="" />
                <h4>目标：“不出险，少出险，出小险”</h4>
              </div>
            </div>
          </div>
        </div>

        <div className="newsContent">
          <div className="content">
            <div className="title">
              <img src={title4} alt="" />
            </div>
            <ul className="newsList">
              {/* 输出新闻 */}
              {NewsList.slice(0, 5).map((news, i) => (
                <a
                  href={news.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={i}
                >
                  <li>
                    <span>
                      <b>
                        {news.date.split('-')[1]}.{news.date.split('-')[2]}
                      </b>
                      {news.date.split('-')[0]}
                    </span>
                    <div>
                      <h4>{news.title}</h4>
                      <p>{news.info}</p>
                    </div>
                  </li>
                </a>
              ))}
            </ul>
          </div>
        </div>
        <Footer></Footer>
      </div>
    )
  }
}

export default Home
